@import "../public/palleon/css/select2.scss";
@import "../public/palleon/css/spectrum.scss";
@import "../public/palleon/css/ruler.scss";
@import "../public/palleon/css/toastr.scss";
@import "../public/palleon/css/style.scss";
@import "../public/palleon/css/dark.scss";




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.file-select-slider-block {

  .slick-list {
    display: flex;

    .slick-track {
      display: flex;
      // height: 314px;

      .slick-slide {
        display: flex;
      }
    }
  }
  
  .slick-slide>div {
    display: flex;
    width: 100%;
  }

  .file-select-slider {

    .slick-slide {
      outline: none;

      &:focus {
        outline: none;
      }

    }
  }
}

body {
  &.no-scroll {
      @media only screen and (min-width: 601px) {
          overflow: hidden;
          position: fixed;
      }
  }
}


.frappe-palleon{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1500;
  color: white;

  #palleon-body{
    background: #212121;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}


// some codes are injected to the DOM by palleon to avoid it.  
.sp-hidden {
  display: none;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 13px;
  white-space: initial;
  line-height: 1.4;
}

.post-calendar {
  &.rbc-calendar {
    min-height: calc(-100px + 100vh);
    height: auto !important;
  }

  .rbc-month-row {
    overflow: unset !important;
  }
}

.fr-text-profile {
  background-color: #ddd;
  border-radius: 4px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}

